import $http from '@/utils/axios'

export const getCountry = (params, success, failure) => {
  $http.get('/api/getCountry', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const reg = (params, success, failure) => {
  $http.post('/api/reg', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const login = (params, success, failure) => {
  $http.post('/api/login', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const getKaptcha = (params, success, failure) => {
  $http.get('/api/email/kaptcha', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const sendEmail = (params, success, failure) => {
  $http.get('/api/email/send', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const setPassword = (params, success, failure) => {
  $http.post('/api/email/setPassword', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const getResidentialPriceList = (params, success, failure) => {
  $http.get('/api/price/getResidentialPriceList', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const getDatacenterPriceList = (params, success, failure) => {
  $http.get('/api/price/getDatacenterPriceList', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const getFaqLabel = (params, success, failure) => {
  $http.get('/api/faq/getFaqLabel', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const getFaqList = (params, success, failure) => {
  $http.get('/api/faq/getFaqList', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}