import $http from '@/utils/axios'

export const getUserInfo = (params, success, failure) => {
  $http.get('/api/user/getInfo', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const fixUserInfo = (params, success, failure) => {
  $http.post('/api/user/fix', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const createOrder = (params, success, failure) => {
  $http.post('/api/order/createOrder', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const getApplyInfos = (params, success, failure) => {
  $http.get('/api/user/getApplyInfos', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const getOrders = (params, success, failure) => {
  $http.get('/api/order/getOrders', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}

export const getApplyLog = (params, success, failure) => {
  $http.get('/api/apply/getApplyLog', params, (res) => {
    success(res)
  }, (err) => {
    failure(err)
  })
}