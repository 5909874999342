<template>
  <div class="mine-page">
    <el-form 
      class="mine-form"
      ref="regForm" 
      :model="regForm" 
      :rules="regRules" 
      label-width="140px">
      <!-- <el-form-item label="First Name:" prop="firstName">
        <el-input type="text" v-model="regForm.firstName" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="Last Name:" prop="lastName">
        <el-input type="text" v-model="regForm.lastName" maxlength="30"></el-input>
      </el-form-item> -->
      <el-form-item label="Username:" prop="username">
        <el-input type="text" v-model="regForm.username" maxlength="30"></el-input>
      </el-form-item>
      <!-- <el-form-item label="Country:" prop="country">
        <el-select v-model="regForm.country" filterable placeholder="Country">
          <el-option
            v-for="(item, index) in countrys"
            :key="index"
            :label="item.countryEnname"
            :value="item.countryEnname">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="Email:" prop="email">
        <el-input type="text" v-model="regForm.email" maxlength="30" disabled></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="handleUpdate">Update Profile</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getCountry } from '@/apis/common'
import { getUserInfo, fixUserInfo } from '@/apis/mine'

export default {
  components: {},
  props: {},
  data () {
    return {
      regForm: {
        userId: '',
        firstName: '',
        lastName: '',
        username: '',
        country: '',
        email: ''
      },
      regRules: {
        // firstName: [
        //   { required: true, message: 'FirstName', trigger: 'blur' }
        // ],
        // lastName: [
        //   { required: true, message: 'LastName', trigger: 'blur' }
        // ],
        username: [
          { required: true, message: 'Username', trigger: 'blur' }
        ],
        // country: [
        //   { required: true, message: 'Country', trigger: 'change' }
        // ],
        email: [
          { required: true, message: 'Email', trigger: 'blur' },
          { type: 'email', message: 'Please enter the correct email', trigger: ['blur'] }
        ]
      },
      countrys: []
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.common.userInfo
    })
  },
  watch: {},
  methods: {
    ...mapMutations(['setLoading', 'setUserInfo']),
    init() {
      // console.log(this.userInfo)
      this.setLoading({
        state: true,
        text: ''
      })
      // getCountry({}, (res) => {
      //   // console.log(res)
      //   this.countrys = res
      // }, (err) => {
      //   // console.log(err)
      // })
      getUserInfo({
        userId: this.userInfo.id
      }, (res) => {
        // console.log(res)
        this.regForm.userId = res.id
        this.regForm.firstName = res.firstName
        this.regForm.lastName = res.lastName
        this.regForm.username = res.username
        this.regForm.country = res.country
        this.regForm.email = res.email
        this.setLoading({
          state: false,
          text: ''
        })
      }, (err) => {
        // console.log(err)
        this.setLoading({
          state: false,
          text: ''
        })
      })
    },
    handleUpdate() {
      this.$refs['regForm'].validate(valid => {
        if (valid) {
          // if (this.regForm.country == '') {
          //   this.$message.warning('Please select a country')
          //   return false
          // }
          // console.log(this.regForm)
          this.setLoading({
            state: true,
            text: ''
          })
          fixUserInfo({
            firstName: this.regForm.firstName,
            lastName: this.regForm.lastName,
            username: this.regForm.username,
            email: this.regForm.email,
            country: this.regForm.country
          }, (res) => {
            // console.log(res)
            this.$message.success('success')
            var userInfo = this.userInfo
            userInfo.firstName = this.regForm.firstName
            userInfo.lastName = this.regForm.lastName
            userInfo.username = this.regForm.username
            userInfo.email = this.regForm.email
            userInfo.country = this.regForm.country
            // console.log(userInfo)
            this.setUserInfo(userInfo)
            this.setLoading({
              state: false,
              text: ''
            })
          }, (err) => {
            this.setLoading({
              state: false,
              text: ''
            })
          })
        } else {
          return false
        }
      })
    }
  },
  created () {
    this.init()
  },
  mounted () {},
  destroyed () {}
}
</script>